import UiRootCategoryItemsModel from './data/ui-rootCategory-items-model';

const Config = {
    LOGGING_ENABLED: true,
    // ROLES: {
    //     ADMIN: 'admin',
    //     HOTELIER_EDITOR: 'hotelier_editor',
    //     HOTELIER_REVIEWER: 'hotelier_reviewer',
    // },
    LOCAL_DATA_KEYS: {
        ACCESS_TOKEN: 'hcn-dinein-access-token',
        GROUP: 'hcn-dinein-group',
        HOTEL: 'hcn-dinein-hotel',
        ROOM: 'hcn-dinein-room',
        TABLET_TOKEN: 'hcn-dinein-tablet_token',
        PROVIDER_ID: 'provider_id',
        MENU_ID: 'menu_id',
        ROOTCATEGORY_CATEGORY: 'rootcategory-category',
        CATEGORY_ID: 'category_id',
    },
    DEFAULT_IMAGE_FOR_ITEM: 'https://dinein-bucket.s3.us-east-2.amazonaws.com/photo-unavailable.png',
    IMAGE_PLACEHOLDER_TYPES: {
        ROOTCATEGORY: 'rootCategory',
        PROVIDER: 'provider',
        ITEM: 'item',
        ICONS: 'icons',
    },
    MAIN_MODE_TYPES: {
        HOME: 'home',
        PROVIDER: 'provider-home',
        ROOTCATEGORY_CATEGORY: 'rootCategory-category',
        SEARCH: 'search',
    },
    REDUX_REDUCER_TYPES: {
        SET_LOADING: 'SET_LOADING',
        SET_PROVIDERS: 'SET_PROVIDERS',
        SET_PROVIDER: 'SET_PROVIDER',
        SET_PROVIDER_ROOTCATEGORIES: 'SET_PROVIDER_ROOTCATEGORIES',
        SET_SELECTED_ROOTCATEGORY_CATEGORY_ID: 'SET_SELECTED_ROOTCATEGORY_CATEGORY_ID',
        SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
        SET_ROOTCATEGORY_CATEGORIES: 'SET_ROOTCATEGORY_CATEGORIES',
        SET_SEARCHING_ITEMS: 'SET_SEARCHING_ITEMS',
        SET_PROVIDERS_PREFERENCES: 'SET_PROVIDERS_PREFERENCES',
        SET_ACTIVE_ROOTCATEGORIES: 'SET_ACTIVE_ROOTCATEGORIES',
        SET_ACTIVE_ROOTCATEGORY: 'SET_ACTIVE_ROOTCATEGORY',
        SET_SIMPLE_MODAL: 'SET_SIMPLE_MODAL',
        SET_GROUP: 'SET_GROUP',
        SET_PROPERTY: 'SET_PROPERTY',
        FONT_SIZE: 'SET_FONT_SIZE',
        PICKUP_DESCRIPTION: 'PICKUP_DESCRIPTION',
        VALID_CODE: 'VALID_CODE',
        SET_SUBTOTAL: 'SET_SUBTOTAL',
        SET_TOTAL: 'SET_TOTAL',
        SET_TIP: 'SET_TIP',
        SET_DISCOUNT: 'SET_DISCOUNT',
        SET_PROVIDER_STATUS: 'SET_PROVIDER_STATUS',
        SET_PAYMENT: 'SET_PAYMENT',
        DINEIN: 'DINEIN',
        SET_CART_ITEMS: 'SET_CART_ITEMS',
        SET_GS_DATA: 'SET_GS_DATA',
    },
    getAccessTokenUsername: () => {
        return 'dinein-ui';
    },
    getAccessTokenPassword: () => {
        return 'sVoBJtc7WIruNBfdT71DHRgdRP9E9pM3QzTubRRgpnp5g9GeRBOCkDOHgu7LkajD';
    },
    getBasePath: () => {
        return process.env.REACT_APP_API_PATH_DINEIN_BACKEND;
    },
    getSocketsPath: () => {
        return process.env.REACT_APP_API_PATH_SOCKETS;
    },
    getNavigatorBasePath: () => {
        return process.env.REACT_APP_API_PATH_NAVIGATOR_BACKEND;
    },
    getAuthBasePath: () => {
        return process.env.REACT_APP_API_PATH_AUTH_BACKEND;
    },
    isDineIn: () => {
        return process.env.REACT_APP_DINE_IN === 'true';
    },
    getProviderId: () => {
        return '10000000-0000-0000-0000-000000000000';
    },
    getRootCategoryId: async () => {
        return await UiRootCategoryItemsModel.remote_getActiveRootCategorys();
    },
    getS3BucketPrefix: () => {
        return process.env.REACT_APP_S3_BUCKET_PREFIX;
    },
};

export default Config;
